import { Head } from '../components/Head'

import { 
  Box,
  Heading,
} from '@chakra-ui/react'

export const Landing = () => {
  return (
    <Box>
      <Head title="Home" description="home" />
      <Box textAlign={'center'} m={20}>
        <Heading as="h1" size="4xl" mb="6" fontFamily={'Arial Black'}>
          ERC-721
        </Heading>
        <Heading as="h1" size="xl" mb="6">
          Mint NFT with your contract!
        </Heading>
      </Box>
    </Box>
  )
}
