import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { http } from '../../api/http';
import { Head } from '../../components/Head'
import { ABI_ERC721, Network, getScanUrl } from '../../common/constants';

import { 
  usePrepareContractWrite, 
  useContractWrite, 
  useAccount, 
  useWaitForTransaction, 
  useNetwork, 
  useSwitchNetwork
} from 'wagmi'

import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
  FormControl,
  FormLabel,
  Input,
  Link,
  ButtonGroup,
} from '@chakra-ui/react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

interface TokenResponse {
  token: Token,
}

interface Token {
  cid:         string,
	name:        string,
  description: string,
  image:       string,
  attributes:  Attribute[],
  mint:        boolean,
}

interface Attribute {
  id: number,
  trait_type: string;
  value: string;
}

export const TokenShow = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { chain } = useNetwork()
  const { isConnected } = useAccount()
  const { switchNetwork } = useSwitchNetwork()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [error, setError] = useState<string>("");

  const [token, setToken] = useState<Token>();

  const [to, setTo] = useState<String>('');

  let { address } = useParams();

  const contractABI = ABI_ERC721;

  const { config } = usePrepareContractWrite({
    address: address as `0x${string}`,
    abi: contractABI,
    functionName: 'mint',
    args: [to, 'ipfs://' + token?.cid],
    enabled: Boolean(to),
  });

  const writeFn = useContractWrite(config)

  const waitFn = useWaitForTransaction({
    hash: writeFn.data?.hash,
  })

  const handleChange = async (event: any) => {
    setTo(event.target.value);
  };

  useEffect(() => {
    if (!isConnected) {
      navigate(`/`);
    }
    if (waitFn.isLoading) {
      if (isOpen) {
        return
      }
      onOpen();
      (async () => {
        try {
          const response = await http<TokenResponse>(`/api/contracts/${params.chain}/${params.address}/tokens/${params.token_id}/transaction`, "POST", {
            chain: chain?.name.toLowerCase(),
            transaction_hash: writeFn.data?.hash,
          });
          const body = response.parsedBody
          if (!body) {
            setError('response error')
            return
          }
        } catch (err: any) {
          setError(err.toString());
        } finally {
        }
      })();
    }
    if (writeFn.isSuccess && waitFn.isSuccess) {
      navigate(`/contracts/${params.chain}/${params.address}/tokens`);
    }
  
    const getPosts = async () => {
      try {
        const response = await http<TokenResponse>(`/api/contracts/${params.chain}/${params.address}/tokens/${params.token_id}`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setToken(body.token);
      } catch (err: any) {
        setError(err.toString());
      } finally {
      }
    }
    getPosts();
  },[params, isConnected, navigate, waitFn.isLoading, onOpen, chain?.name, writeFn.data?.hash, waitFn.isSuccess, writeFn.isSuccess, isOpen]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (params.chain !== String(chain?.id)) {
      switchNetwork?.(parseInt(params.chain!));
      return;
    }
    writeFn.write?.();
  }


  return (
    <>
      <Head title="Token" description="Token" />
      <div>
        <Box
          mb={5}
          pb={3}
          borderBottom={'1px'}
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Flex>
            <Link fontSize={'xl'} fontWeight={600} href={`/contracts`}>Contracts</Link>
            <Text pl={3} pr={3}>/</Text>
            <Link fontSize={'xl'} fontWeight={600} href={`/contracts/${params.chain}/${params.address}/tokens`}>
              Tokens
            </Link>
            <Text pl={3} pr={3}>/</Text>
            <Text fontSize={'xl'} fontWeight={600}>
              Show
            </Text>
          </Flex>
        </Box>
        <Box mt={6} mb={6} color='orange'>
          {error}
        </Box>
        <Container maxW={'7xl'}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}>
            <Flex>
              {token?.image && (
                <Image
                  rounded={'md'}
                  alt={'product image'}
                  src={`https://nftstorage.link/ipfs/${token?.image}`}
                  fit={'cover'}
                  align={'center'}
                  w={'100%'}
                  h={{ base: '100%', sm: '400px', lg: '500px' }}
                />
              )}
            </Flex>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={'header'}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                  {token?.name}
                </Heading>
              </Box>

              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={'column'}
                divider={
                  <StackDivider borderColor={useColorModeValue('gray.200', 'gray.600')} />
                }>
                <Text fontSize={'lg'}>
                  {token?.description}
                </Text>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={useColorModeValue('yellow.500', 'yellow.300')}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}>
                    Traits
                  </Text>

                  {token?.attributes.map((attribute) => (
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} key={attribute.id}>
                    <List spacing={2}>
                        <ListItem>{attribute.trait_type}</ListItem>
                      </List>
                      <List spacing={2}>
                        <ListItem>{attribute.value}</ListItem>
                      </List>
                    </SimpleGrid>
                  ))}
                </Box>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent={'center'}>
                <Text fontSize={13}>{token?.cid}</Text>
              </Stack>
              
              {token?.mint === false && (
                <form onSubmit={onSubmit}>
                  <FormControl>
                    <FormLabel>To</FormLabel>
                    <Input type="text" name="to" defaultValue={""} onChange={handleChange}/>
                  </FormControl>
                  <ButtonGroup variant='outline' spacing='6' mt={4} w={'full'}>
                    <Button colorScheme='teal' type='submit' w={'full'} isLoading={writeFn.isLoading}>
                      Mint
                    </Button>
                  </ButtonGroup>
                </form>
              )}

            </Stack>
          </SimpleGrid>
        </Container>
        
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Mint</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {waitFn.isLoading &&
                <Box textAlign={'center'}>
                  <Image src={`/images/loading.gif`} />
                  <Link href={`${getScanUrl(chain?.id as Network)}/tx/${writeFn.data?.hash}`} isExternal>
                    View on Etherscan
                  </Link>
                </Box>
              }
              {waitFn.isError && <p>Error: {waitFn.error!.message}</p>}
            </ModalBody>
          </ModalContent>
        </Modal>

      </div>
    </>
  )
}
