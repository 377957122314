import { useState,useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { http } from '../../api/http';
import { Head } from '../../components/Head'
import Dropzone from "react-dropzone";

import { useAccount } from 'wagmi'

import {
  Box,
  Link,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

interface TokenResponse {
  token: Token,
}

interface Token {
  id:          number,
	name:      string,
  description: string,
  image:       string,
}

interface Attribute {
  trait_type: string;
  value: string;
}

export const TokenNew = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { isConnected } = useAccount()

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [state, setState] = useState<Token>({
    id: 0,
    name: "",
    description: "",
    image: "",
  });

  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const addAttribute = (e: any) => {
    e.preventDefault();
    setAttributes([...attributes, { trait_type: '', value: '' }]);
  };
  const updateAttribute = (index: number, key: 'trait_type' | 'value', newValue: string) => {
    setAttributes(attributes.map((attribute, i) => i === index ? { ...attribute, [key]: newValue } : attribute));
  };

  const handleChange = async (event: any) => {
    if (event.target.type === "number") {
      setState({ ...state, [event.target.name]: Number(event.target.value) });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String1 = reader.result as string;
      setPreviewImage(base64String1);
      const base64String = base64String1.split(",")[1];
      // alert(base64String);
      setSelectedImage(base64String);
    };

    reader.readAsDataURL(file);
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    if (selectedImage) {
      try {
        setLoading(true);
        const response = await http<TokenResponse>(`/api/contracts/${params.chain}/${params.address}/tokens/new`, "POST", {
          name:           state.name,
          description:    state.description,
          image:          selectedImage,
          attributes:     attributes,
        });
        const resBody = response.parsedBody
        if (!resBody) {
          setError('response error')
          setLoading(false);
          return
        }
        const token = resBody.token;
        navigate(`/contracts/${params.chain}/${params.address}/tokens/${token.id}`);
      } catch (err: any) {
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!isConnected) {
      navigate(`/`);
    }
  },[navigate, isConnected]);

  return (
    <>
      <Head title="New Token" description="New Token" />
      <Box>
        <Box
          mb={5}
          pb={3}
          borderBottom={'1px'}
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Flex>
            <Link fontSize={'xl'} fontWeight={600} href={`/contracts`}>Contracts</Link>
            <Text pl={3} pr={3}>/</Text>
            <Link fontSize={'xl'} fontWeight={600} href={`/contracts/${params.chain}/${params.address}/tokens`}>
              Tokens
            </Link>
            <Text pl={3} pr={3}>/</Text>
            <Text fontSize={'xl'} fontWeight={600}>
              New
            </Text>
          </Flex>
        </Box>
        <Box mt={6} mb={6} color='orange'>
          {error}
        </Box>
        <form>
          <FormControl mb={5}>
            <FormLabel>Name</FormLabel>
            <Input type='text' name="name" defaultValue={""} onChange={handleChange} />
          </FormControl>
          <FormControl mb={5}>
            <FormLabel>Description</FormLabel>
            <Textarea name="description" defaultValue={""} onChange={handleChange} />
          </FormControl>
          <FormControl mb={5}>
            <FormLabel>Image</FormLabel>
            <Dropzone onDrop={handleDrop} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={{ border: "2px dashed #cccccc", padding: "20px", textAlign: "center" }}>
                  <input {...getInputProps()} />
                  {previewImage ? (
                    <img src={previewImage} alt="Selected" style={{ width: "auto", maxHeight: "200px", marginBottom: "10px" }} />
                  ) : (
                    <p>Drag and drop an image here, or click to select an image.</p>
                  )}
                </div>
              )}
            </Dropzone>
          </FormControl>
          <FormControl mb={5}>
            <FormLabel>Attributes</FormLabel>
            {attributes.map((attribute, index) => (
              <Box key={index} mb={2}>
                <Input
                  type="text"
                  width={200}
                  mr={2}
                  placeholder="Trait type"
                  value={attribute.trait_type}
                  onChange={(e) => updateAttribute(index, 'trait_type', e.target.value)}
                />
                <Input
                  type="text"
                  width={200}
                  placeholder="Value"
                  value={attribute.value}
                  onChange={(e) => updateAttribute(index, 'value', e.target.value)}
                />
              </Box>
            ))}
            <Button onClick={addAttribute}>+</Button>
          </FormControl>
          <FormControl mb={5}>
            <Button 
              colorScheme='teal' 
              onClick={handleUpload}
              loadingText="Submitting"
              isLoading={loading}
              w={'full'}
            >
              Save to IPFS
            </Button>
          </FormControl>
        </form>
      </Box>
    </>
  )
}
