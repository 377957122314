import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { http } from '../../api/http';
import { Head } from '../../components/Head'
import { ABI_FACTORY, getContractAddress, Network, getScanUrl } from '../../common/constants';

import { usePrepareContractWrite, useContractWrite, useNetwork, useWaitForTransaction, useAccount, useSwitchNetwork } from 'wagmi'

import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  ButtonGroup,
  Box,
  Flex,
  Link,
  Text,
  useColorModeValue,
  Image,
} from '@chakra-ui/react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

interface TransactionResponse {
  result: String
}

interface Contract {
	name:      string,
  symbol:    string,
  chain:     string,
}

export const ContractNew = () => {
  const navigate = useNavigate();

  const { chain } = useNetwork()
  const { isConnected } = useAccount()
  const { switchNetwork } = useSwitchNetwork()

  const [error, setError] = useState<string>("");
  const [state, setState] = useState<Contract>({
    name: "",
    symbol: "",
    chain: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure()

  const contractABI = ABI_FACTORY;
  
  const { config } = usePrepareContractWrite({
    address: getContractAddress(chain?.id as Network) as `0x${string}`,
    abi: contractABI,
    functionName: 'createERC721',
    args: [state.name, state.symbol],
  });

  const writeFn = useContractWrite(config)

  const waitFn = useWaitForTransaction({
    hash: writeFn.data?.hash,
  })

  const handleChange = async (event: any) => {
    if (event.target.type === "number") {
      setState({ ...state, [event.target.name]: Number(event.target.value) });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!state.name || !state.symbol || !state.chain) {
      setError("Please fill all fields.");
      return;
    }
    let chainID = parseInt(state.chain)
    if (chain?.id !== chainID) {
      switchNetwork?.(chainID);
      // setError("Please select the same chain as your wallet.");
      return;
    }
    writeFn.write?.();
  }

  useEffect(() => {
    if (!isConnected) {
      navigate(`/`);
    }
    if (writeFn.isSuccess && waitFn.isSuccess) {
      navigate(`/contracts`);
    }
    if (waitFn.isLoading) {
      if (isOpen) {
        return
      }
      onOpen();
      (async () => {
        try {
          const response = await http<TransactionResponse>(`/api/contracts/${state.chain}/transaction`, "POST", {
            transaction_hash: writeFn.data?.hash,
          });
          const body = response.parsedBody
          if (!body) {
            setError('response error')
            return
          }
        } catch (err: any) {
          setError(err.toString());
        } finally {
        }
      })();
    }
  }, [writeFn.isSuccess, waitFn.isLoading, waitFn.isSuccess, navigate, isConnected, onOpen, writeFn.data?.hash, state.chain, isOpen]);

  return (
    <>
      <Head title="New Contract" description="New Contract" />
      <Box>
        <Box
          mb={5}
          pb={3}
          borderBottom={'1px'}
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Flex>
            <Link fontSize={'xl'} fontWeight={600} href={`/contracts`}>Contracts</Link>
            <Text pl={3} pr={3}>/</Text>
            <Text fontSize={'xl'} fontWeight={600}>
              New
            </Text>
          </Flex>
        </Box>
        <Box mt={6} mb={6} color='orange'>
          {error}
        </Box>
        <form onSubmit={onSubmit}>
          <FormControl mb={5}>
            <FormLabel>Name</FormLabel>
            <Input type='text' name="name" defaultValue={""} onChange={handleChange} />
          </FormControl>
          <FormControl mb={5}>
            <FormLabel>Symbol</FormLabel>
            <Input type='text' name="symbol" defaultValue={""} onChange={handleChange} />
          </FormControl>
          <FormControl mb={5}>
            <FormLabel>Chain</FormLabel>
            <Select placeholder='Select chain' name="chain" defaultValue={""} onChange={handleChange}>
              <option value="1">Ethereum</option>
              <option value="5">Goerli</option>
              <option value="11155111">Sepolia</option>
              <option value="137">Polygon</option>
              <option value="80001">Mumbai</option>
              <option value="8453">Base</option>
              <option value="84531">Base Goerli</option>
            </Select>
          </FormControl>
          <ButtonGroup variant='outline' spacing='6' mt={4} w={'full'}>
            <Button colorScheme='teal' type='submit' w={'full'} isLoading={writeFn.isLoading}>
              Deploy
            </Button>
          </ButtonGroup>
        </form>
      </Box>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Contract</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {waitFn.isLoading &&
              <Box textAlign={'center'}>
                <Image src={`/images/loading.gif`} />
                <Link href={`${getScanUrl(chain?.id as Network)}/tx/${writeFn.data?.hash}`} isExternal>
                  View on Etherscan
                </Link>
              </Box>
            }
            {waitFn.isError && <p>Error: {waitFn.error!.message}</p>}
          </ModalBody>
        </ModalContent>
      </Modal>

    </>
  )
}
