import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Heading,
} from '@chakra-ui/react'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSignMessage,
  useSwitchNetwork,
} from 'wagmi'

import { Select } from 'chakra-react-select'

// import { watchAccount } from '@wagmi/core'

import { http } from '../api/http';

interface SignResponse {
  access_token: string,
}

export const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();

  const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
  const { address, isConnected } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { data: ensAvatar } = useEnsAvatar({ name: ensName })
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()

  const [ connectedAddress, setConnectedAddress ] = useState<`0x${string}` | null>(null)

  const avatar = ensAvatar ?? `https://api.dicebear.com/6.x/bottts-neutral/svg?seed=${address}`

  const chainOptions = [
    { value: "1", label: "Ethereum" },
    { value: "5", label: "Goerli" },
    { value: "11155111", label: "Sepolia" },
    { value: "137", label: "Polygon" },
    { value: "80001", label: "Mumbai" },
    { value: "8453", label: "Base"},
    { value: "84531", label: "BaseGoerli" },
  ];

  const message = "Welcome to ERC-721.xyz!\b\n\nThis is a message to verify that you are the owner of this address.\n\nIf you are the owner of this address, please sign this message to continue."

  const viewAddress = address ? address.slice(0, 6) + '...' + address.slice(-4) : ''

  const wagmiSign = useSignMessage({
    onSuccess(...args) {
      console.log('sign SYNC success:', ...args);
      (async () => {
        try {
          const response = await http<SignResponse>(`/personal/sign`, "POST", {
            message: signMessage,
            signature: args[0],
            address: address,
          });
          const resBody = response.parsedBody
          if (!resBody) {
            return
          }
          localStorage.setItem('access_token', resBody.access_token)
          navigate(`/contracts`);
        } catch (err: any) {
        } finally {
        }
      })();
    },
    onError(...args) {
      console.log('sign SYNC error:', ...args);
      disconnect()
    },
    onMutate(...args) {
      console.log('sign mutate:', ...args);
    },
    onSettled(...args) {
      console.log('sign settled:', ...args);
    },
  });

  const wagmiDisconnect = useDisconnect({
    onSuccess(...args) {
      console.log('disconnect SYNC success:', ...args);
      localStorage.removeItem('access_token')
    },
    onError(...args) {
      console.log('disconnect SYNC error:', ...args);
    },
    onMutate(...args) {
      console.log('disconnect mutate:', ...args);
    },
    onSettled(...args) {
      console.log('disconnect settled:', ...args);
    },
  });

  const wagmiSwitchNetwork = useSwitchNetwork({
    onSuccess(...args) {
      console.log('switchNetwork SYNC success:', ...args);
    },
    onError(...args) {
      console.log('switchNetwork SYNC error:', ...args);
    },
    onMutate(...args) {
      console.log('switchNetwork mutate:', ...args);
    },
    onSettled(...args) {
      console.log('switchNetwork settled:', ...args);
    },
  });

  let wagmiSignM = wagmiSign.signMessage

  const signMessage = message + "\n\nWallet address: " + address;

  useEffect(() => {
    if (isConnected && connectedAddress != null && connectedAddress !== address) {
      wagmiDisconnect.disconnect?.()
      setConnectedAddress(null)
      return
    }
    if (isConnected && connectedAddress === null) {
      setConnectedAddress(address!)
    }
    if (isConnected && localStorage.getItem('access_token') === null) {
      wagmiSignM({ message: signMessage })
    }
    // eslint-disable-next-line
  }, [isConnected, wagmiSignM, signMessage]); 

  const changeChain = async (e: any) => {
    const chainID = parseInt(e.value)
    wagmiSwitchNetwork.switchNetwork?.(chainID)
  }

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Heading size='md'>
            <Box onClick={() => navigate('/contracts')} cursor={'pointer'} fontFamily={'Arial Black'}>
              ERC-721
            </Box>
          </Heading>

          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
              {isConnected && (
                <Menu>
                  <Select
                    useBasicStyles
                    isSearchable={false}
                    name="chains"
                    options={chainOptions}
                    required={true}
                    closeMenuOnSelect={true}
                    onChange={(e: any) => {
                      changeChain(e)
                    }}
                    value={
                      chainOptions.find((option) => parseInt(option.value) === chain?.id)
                    }
                  />
                  <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}>
                    <Avatar
                      size={'sm'}
                      src={avatar}
                    />
                  </MenuButton>
                  <MenuList alignItems={'center'}>
                    <br />
                    <Center>
                      <Avatar
                        size={'2xl'}
                        src={avatar}
                      />
                    </Center>
                    <br />
                    <Center>
                      <p>{ensName ?? viewAddress}</p>
                    </Center>
                    <br />
                    <MenuDivider />
                    <MenuItem onClick={() => navigate('/contracts') }>Your Contracts</MenuItem>
                    <MenuItem onClick={() => wagmiDisconnect.disconnect()}>Disconnect</MenuItem>
                  </MenuList>
                </Menu>
              )}

              {!isConnected && (
                connectors.map((connector) => (
                  <Button
                    disabled={!connector.ready}
                    key={connector.id}
                    onClick={() => connect({ connector })}
                  >
                    {/* {connector.name} */}
                    Connect Wallet
                    {!connector.ready && ' (unsupported)'}
                    {isLoading &&
                      connector.id === pendingConnector?.id &&
                      ' (connecting)'}
                  </Button>
                ))
              )}

              {error && <div>{error.message}</div>}

              <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button>

            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
