import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { http } from '../../api/http';
import { Head } from '../../components/Head'
import { getChain, getChainColor, getScanUrl, Network } from '../../common/constants'

import { useNetwork, useAccount } from 'wagmi'

import { 
  SimpleGrid,
  Box,
  Button,
  Flex,
  Spacer,
  Stack,
  Text,
  List,
  ListItem,
  ListIcon,
  Link,
  useColorModeValue,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

interface ContractsResponse {
  contracts: Contract[]
}

interface Contract {
  id: number,
  address: string,
  name: string,
  symbol: string,
  owner: string,
  chain: number,
}

export const ContractList = () => {
  const navigate = useNavigate();

  const { chain } = useNetwork()
  const { address, isConnected } = useAccount()

  const [error, setError] = useState<string>("");
  const [contracts, setContracts] = useState<Contract[]>([]);

  useEffect(() => {
    const getContracts = async () => {
      try {
        const response = await http<ContractsResponse>(`/api/contracts`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setContracts(body.contracts);
      } catch (err: any) {
        setError(err.toString());
      } finally {
      }
    }
    if (isConnected) {
      getContracts();
    } else {
      navigate(`/`);
    }
  },[navigate, isConnected, chain, address]);

  return (
    <>
      <Head title="Contracts" description="Your Contracts" />
      <Box>
        <Box
          mb={5}
          pb={3}
          borderBottom={'1px'}
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Text fontSize={'xl'} fontWeight={600}>
            Contracts
          </Text>
        </Box>

        <Flex dir="row" mb={10}>
          <Spacer />
          <Button as={'a'} colorScheme='blue' variant='outline' href={`/contracts/new`}>New Contract</Button>
        </Flex>
        <Box mt={6} mb={6} color='orange'>
          {error}
        </Box>
        <SimpleGrid columns={2} spacing={10}>
          {contracts?.map(contract => (
            <Box
              key={contract.id}
              w={'full'}
              bg='white'
              rounded={'md'}
              overflow={'hidden'}
              border='1px'
              borderColor='gray.200'
              _dark={{ bg:'gray.800', borderColor: 'gray.700'}}
            >
              <Stack
                textAlign={'center'}
                p={6}
                color='gray.800' _dark={{ color: 'white' }}
                align={'center'}>
                <Text
                  fontSize={'sm'}
                  fontWeight={500}
                  bg={getChainColor(contract.chain as Network)}
                  _dark={{ bg: getChainColor(contract.chain as Network) }}
                  p={2}
                  px={3}
                  color={'white'}
                  rounded={'full'}>
                  {getChain(contract.chain as Network)}
                </Text>
                <Stack direction={'row'} align={'center'} justify={'center'}>
                  <Text fontSize={'2xl'} fontWeight={600}>
                    {contract.name}
                  </Text>
                  <Text fontSize={'2xl'} fontWeight={400} color={'gray.500'}>({contract.symbol})</Text>
                </Stack>
              </Stack>

              <Box bg='gray.50' _dark={{ bg: 'gray.900'}} px={6} py={10}>
                <List spacing={3}>
                  <ListItem fontSize={'sm'}>
                    {contract.address}
                    <Link href={`${getScanUrl(chain?.id as Network)}/address/${contract.address}`} target='_blank' marginLeft={2}>
                      <ListIcon as={ExternalLinkIcon} color="green.400" />
                    </Link>
                  </ListItem>
                </List>
                <Button
                  as={'a'}
                  mt={10}
                  w={'full'}
                  href={`/contracts/${contract.chain}/${contract.address}/tokens`}
                  >
                  Show Tokens
                </Button>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </>
  )
}
