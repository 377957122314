import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { http } from '../../api/http';
import { Head } from '../../components/Head'

import { useAccount } from 'wagmi'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Link,
  Flex,
  Button,
  Spacer,
  useColorModeValue,
  Text,
} from '@chakra-ui/react'

interface TokensResponse {
  tokens: Token[]
}

interface Token {
  id: number,
  name: string,
  description: string,
  image: string,
  attributes: Attribute[],
  mint: boolean,
}

interface Attribute {
  trait_type: string;
  value: string;
}

export const TokenList = () => {
  const navigate = useNavigate();
  const params = useParams();
  
  const { isConnected } = useAccount()

  const [error, setError] = useState<string>("");
  const [tokens, setTokens] = useState<Token[]>([]);

  const chainId = params.chain;
  const contractAddress = params.address as `0x${string}`;

  useEffect(() => {
    const getContracts = async () => {
      try {
        const response = await http<TokensResponse>(`/api/contracts/${chainId}/${contractAddress}/tokens`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setTokens(body.tokens);
      } catch (err: any) {
        setError(err.toString());
      } finally {
      }
    }
    if (isConnected) {
      getContracts();
    } else {
      navigate(`/`);
    }
  },[navigate, contractAddress, isConnected, chainId]);

  return (
    <>
      <Head title="Tokens" description="Tokens" />
      <Box>
        <Box
          mb={5}
          pb={3}
          borderBottom={'1px'}
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Flex>
            <Link fontSize={'xl'} fontWeight={600} href={`/contracts`}>Contracts</Link>
            <Text pl={3} pr={3}>/</Text>
            <Text fontSize={'xl'} fontWeight={600}>
              Tokens 
            </Text>
          </Flex>
        </Box>
        <Box mt={6} mb={6} color='orange'>
          {error}
        </Box>
        <Flex dir="row" mb={10}>
          <Spacer />
          <Button as={'a'} colorScheme='blue' variant='outline' href={`/contracts/${params.chain}/${contractAddress}/tokens/new`}>New Token</Button>
        </Flex>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th>Mint</Th>
                <Th>Detail</Th>
              </Tr>
            </Thead>
            <Tbody>
            {tokens?.map(token => (
              <Tr key={token.id}>
                <Td>
                  <img src={`https://nftstorage.link/ipfs/${token?.image}`} alt='' width={50}></img>
                </Td>
                <Td>{token.name}</Td>
                <Td>
                  {token.description.length > 50 ? token.description.substring(0, 50) + "..." : token.description}
                </Td>
                <Td>{String(token.mint)}</Td>
                <Td>
                  <Button as={'a'} href={`/contracts/${params.chain}/${contractAddress}/tokens/${token.id}`}>
                    Show
                  </Button>
                </Td>
              </Tr>
            ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
