import {BrowserRouter, Routes, Route} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react'

import { Header } from "./components/Header";
import { NotFound } from './components/NotFound';

import { Landing } from './pages/Landing';
import { Home } from './pages/Home';

import { ContractList } from './pages/contracts/ContractList';
import { ContractNew } from './pages/contracts/ContractNew';

import { TokenList } from './pages/contracts/TokenList';
import { TokenNew } from './pages/contracts/TokenNew';
import { TokenShow } from './pages/contracts/TokenShow';

import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { mainnet, goerli, sepolia, polygon, polygonMumbai, base, baseGoerli } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
// import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { Box } from "@chakra-ui/react"

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, goerli, sepolia, polygon, polygonMumbai, base, baseGoerli],
  [
    alchemyProvider({ apiKey: 'Te5V_BzFGWWZyvyc9t2OBhj7OANGnB43' }), 
    publicProvider()
  ],
)

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      options: {
        // name: 'Connect Wallet',
        shimDisconnect: false,
      },
    }),
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: 'Connect Wallet',
    //     shimDisconnect: false,
    //   },
    // }),
  ],
  publicClient,
  webSocketPublicClient,
})

function App() {

  const helmetContext = {};

  return (
    <BrowserRouter>
      <HelmetProvider context={helmetContext}>
      <WagmiConfig config={config}>
        <ChakraProvider>
          <Header />
          <Box maxW="980px" mx="auto" padding="30px" pb={20}>
            <Routes>
              <Route path="/" element={<Landing/>} />
              <Route path="/home" element={<Home/>} />
              <Route path="/contracts" element={<ContractList/>}/>
              <Route path="/contracts/new" element={<ContractNew/>} />
              <Route path="/contracts/:chain/:address/tokens" element={<TokenList/>} />
              <Route path="/contracts/:chain/:address/tokens/new" element={<TokenNew/>} />
              <Route path="/contracts/:chain/:address/tokens/:token_id" element={<TokenShow/>} />
              
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </ChakraProvider>
      </WagmiConfig>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
