import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { Head } from '../components/Head'

export const Home = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount()
  
  useEffect(() => {
    if (!isConnected) {
      navigate(`/`);
    }
  },[navigate, isConnected]);

  return (
    <>
      <Head title="Home" description="home" />
      <div>
        <h2>Home</h2>
        <Link to={`/contracts/`}>Contracts</Link>
      </div>
    </>
  )
}
